import { Box, Flex, Icon, Spinner, Text, VStack } from '@chakra-ui/react';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useTranslation } from 'react-i18next';
import useGetLatestQuote from '../hooks/useGetLatestQuote';
import { QuoteItem } from './QuoteItem';
import { Summary } from './Summary';
import Card from 'components/card/Card';
import { FiEdit3 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

type ClientQuoteProps = {
  eventId: string;
};

export const ClientQuote = ({ eventId }: ClientQuoteProps) => {
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('quote');
  const navigate = useNavigate();

  const { isLoading, quote, products, summary } = useGetLatestQuote(eventId);

  const handleEditQuote = useCallback(
    () => navigate(`/main/finance/quotations/${quote?.id}`),
    [quote?.id, navigate],
  );
  return (
    <Card
      ref={ref}
      {...style}
      gridColumn={{ base: 'unset', lg: 'span 2' }}
      w="100%"
      maxHeight="620px"
      borderRadius={{ base: 0, md: '30px' }}
      gap={{ base: 5, lg: '30px' }}
      overflow="auto"
      position="relative">
      {isLoading ? (
        <Box
          position="absolute"
          w="100%"
          h="100%"
          top="50%"
          left="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          transform="translate(-50%, -50%)"
          zIndex="1">
          <Spinner color="brand.500" />
        </Box>
      ) : (
        <>
          <Flex alignItems="center" justify="space-between">
            <Text variant="eventPageCardTitle">{t('event.quote')}</Text>
            <Icon
              as={FiEdit3}
              color="#999EA9"
              w="24px"
              h="24px"
              cursor="pointer"
              onClick={handleEditQuote}
            />
          </Flex>
          <VStack
            w="full"
            spacing={0}
            letterSpacing="tight"
            lineHeight="4"
            gap={{ base: 5, lg: '20px' }}
            color="blackAlpha.600">
            {products.map((product, index) => (
              <QuoteItem
                currency={quote.currency}
                key={product.productId}
                product={product}
                number={index + 1}
              />
            ))}
          </VStack>
          <Summary sum={summary.sum} vat={summary.vat} total={summary.total} />
        </>
      )}
    </Card>
  );
};
