import { HStack } from '@chakra-ui/react';
import { RootState } from 'contexts/redux/store';
import Messages from '../../managment/events/eventInfo/components/Messages';
import Tracker from '../../managment/events/eventInfo/components/Tracker';
import TaskList from './Tasks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MeetingWithCalendar } from 'services/@types';
import { IEvent } from 'services/@types';
import _IMeetingService from 'services/meeting.api';
import { useParams } from 'react-router-dom';
import _eventService from 'services/event.api';
import { useQuery } from '@tanstack/react-query';
import Meetings from './Meetings';
import ClientDetails from './ClientDetails';
import { ClientBrief } from './ClientBrief';
import { ClientQuote } from './ClientQuote';
import LineUp from './LineUp';
import MyContract from './MyContract';

export default function EventPageBottomCards() {
  const { t } = useTranslation();
  const currentTab = useSelector(
    (state: RootState) => state.event.currentEventTab,
  );
  const params = useParams();
  const eventId = params?.id;

  const { data: meetingsAndEventDetails, isLoading } = useQuery<{
    meetings: MeetingWithCalendar[];
    eventDetails: IEvent;
  }>({
    queryKey: [`getMeetings-${eventId}`],
    queryFn: async () => {
      const meetingsResult =
        await _IMeetingService.getMeetings<MeetingWithCalendar>({
          eventId,
          populate: 'calendarId',
          limit: 30,
          sortBy: 'startDate:desc',
        });
      const eventDetails = await _eventService.getEvent(eventId);

      return {
        meetings: meetingsResult.results,
        eventDetails,
      };
    },
    retry: false,
    enabled: !!params?.id,
  });

  if (currentTab === 'main') {
    return (
      <HStack
        spacing={0}
        display={{ base: 'flex', lg: 'grid' }}
        gridTemplateColumns={{
          base: 'unset',
          lg: 'repeat(3, 1fr)',
        }}
        pb="40px"
        gridAutoRows={{ base: 'unset', lg: '535px' }}
        flexDirection={{ base: 'column', lg: 'row' }}
        gap={{ base: 2, lg: 5 }}
        height="max-content">
        <TaskList
          meetingsAndEventDetails={meetingsAndEventDetails}
          isLoading={isLoading}
          shouldScroll
          additionalProps={{ maxHeight: '535px' }}
        />
        <Tracker
          componentTitle={t('event.event_tracker')}
          additionalProps={{ maxHeight: '535px' }}
        />
        <Messages />
      </HStack>
    );
  }

  if (currentTab === 'client_file') {
    return (
      <HStack
        spacing={0}
        display={{ base: 'flex', lg: 'grid' }}
        gridTemplateColumns={{
          base: 'unset',
          lg: 'repeat(6, 1fr)',
        }}
        gridAutoRows={{ base: 'unset', lg: 'minmax(fit-content, 648px)' }}
        alignItems={{ base: 'unset', lg: 'stretch' }}
        flexDirection={{ base: 'column', lg: 'row' }}
        gap={{ base: 2, lg: 5 }}
        pb="40px"
        height={'max-content'}>
        {/* client details */}
        <ClientDetails />
        {/* client brief */}
        <ClientBrief />
        {/* client quote */}
        <ClientQuote eventId={eventId} />

        {/* line up */}
        <LineUp
          additionalProps={{
            style: { gridColumn: 'span 3', gridRowStart: '2' },
          }}
        />
        {/* my contract */}
        <MyContract
          additionalProps={{
            style: { gridColumn: 'span 3', gridRowStart: '2' },
          }}
        />
      </HStack>
    );
  }

  if (currentTab === 'supplier_assignment') {
    return (
      <HStack
        spacing={0}
        display={{ base: 'flex', lg: 'grid' }}
        gridTemplateColumns={{
          base: 'unset',
          lg: 'repeat(6, 1fr)',
        }}
        gridAutoRows={{ base: 'unset', lg: '535px' }}
        alignItems={{ base: 'unset', lg: 'stretch' }}
        flexDirection={{ base: 'column', lg: 'row' }}
        gap={{ base: 2, lg: 5 }}
        pb="40px"
        height={'max-content'}>
        <Meetings
          meetingsAndEventDetails={meetingsAndEventDetails}
          isLoading={isLoading}
        />
        <TaskList
          meetingsAndEventDetails={meetingsAndEventDetails}
          isLoading={isLoading}
          shouldScroll
          additionalProps={{
            style: { gridColumn: 'span 3', gridRowStart: '2' },
            maxHeight: '535px',
          }}
        />
        <Tracker
          componentTitle={t('event.event_tracker')}
          additionalProps={{
            style: { gridColumn: 'span 3', gridRowStart: '2' },
            maxHeight: '535px',
          }}
        />
      </HStack>
    );
  }

  return <></>;
}
