import { useQuery, useQueryClient } from '@tanstack/react-query';
import useDebounce from 'hooks/useDebounce';
import { useCallback, useMemo, useState } from 'react';

import { CalendarEventWithoutType, SearchTermResult } from 'services/@types';
import _calendarEventsService from 'services/calendarEvent.api';

const useCalendarSearch = () => {
  const [search, setSearch] = useState<string>('');
  const queryClient = useQueryClient();

  const debouncedQueryKey = useDebounce(
    useMemo(
      () =>
        search?.length > 0
          ? ['meetingsAndEvents', search]
          : ['meetingsAndEvents'],
      [search],
    ),
    700,
  );

  const { data, isLoading, error } = useQuery<
    SearchTermResult<CalendarEventWithoutType>
  >({
    retry: false,
    queryKey: debouncedQueryKey,
    queryFn: async () => {
      console.log('fetching calendar events');

      if (search.length > 0) {
        const result = (await _calendarEventsService.searchCalendarEvents(
          search,
        )) as SearchTermResult<CalendarEventWithoutType>;
        console.log('result', result);
        return {
          filter: result.filter,
          data: result.data.sort((a, b) => {
            const now = Date.now();
            const aTime = new Date(a.start).getTime();
            const bTime = new Date(b.start).getTime();
            return Math.abs(aTime - now) - Math.abs(bTime - now);
          }),
        };
      }

      return {
        filter: {},
        data: [],
      } as SearchTermResult<CalendarEventWithoutType>;
    },
  });

  const handleSearch = useCallback(
    (search: string) => {
      setSearch(search);
      queryClient.invalidateQueries({
        queryKey: debouncedQueryKey,
      });
    },
    [queryClient, debouncedQueryKey],
  );
  const { data: results, filter } =
    (data as SearchTermResult<CalendarEventWithoutType>) || {
      data: [] as CalendarEventWithoutType[],
      filter: null,
    };
  return {
    results,
    filter,
    isLoading,
    error,
    search,
    handleSearch,
  };
};

export default useCalendarSearch;
