import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { fetchUser } from 'contexts/redux/user/userSlice';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { LuBookmark } from 'react-icons/lu';
import { useDispatch } from 'react-redux';
import { CalendarEvent, MeetingWithCalendar, User } from 'services/@types';
import { useQuery } from '@tanstack/react-query';
import { setCalendarEventDetailsDialog } from 'contexts/redux/dialog/dialogsSlice';
import { setNavigateTo } from 'contexts/redux/createMeeting/createMeetingSlice';

type Props = {
  meeting: MeetingWithCalendar;
  onTop?: boolean;
};

const SuppierMeetingItem = ({ meeting, onTop = false }: Props) => {
  const { i18n, t } = useTranslation();
  const itemColor = '#AA915D';
  const dispatch = useDispatch<any>();

  const { startTime, time, date } = useMemo(() => {
    const start = moment(meeting.startDate).format('hh:mm');
    return {
      startTime: start,
      time: `${start} - ${moment(meeting.endDate).format('hh:mm')}`,
      date: moment(meeting.startDate)
        .locale(i18n.language)
        .calendar(null, {
          sameDay: `[${t('datenames.today')}]`,
          nextDay: `[${t('datenames.tomorrow')}]`,
          nextWeek: 'DD MMM',
          lastDay: `[${t('datenames.yesterday')}]`,
          lastWeek: 'DD MMM',
          sameElse: 'DD MMM',
        }),
    };
  }, [i18n.language, meeting.endDate, meeting.startDate, t]);

  const { data: creatorUser, isLoading } = useQuery<User>({
    queryKey: [`meetingCreatorUser-${meeting.creatorUserId}`],
    queryFn: async () => {
      const result = await dispatch(fetchUser(meeting.creatorUserId));
      return result.payload;
    },
    retry: false,
    enabled: !!meeting.creatorUserId,
  });

  return (
    <VStack
      gap={2}
      spacing={0}
      w="100%"
      h="100%"
      p={'8px 12px'}
      borderRadius="16px"
      border={`1px solid ${itemColor}`}
      color={itemColor}
      letterSpacing={'-0.02em'}
      fontSize="14px"
      lineHeight="15px"
      alignItems={'flex-start'}
      fontWeight="400"
      cursor={'pointer'}
      order={onTop ? -1 : 0}
      onClick={async () => {
        const payload = {
          item: {
            id: meeting.id,
            summary: meeting.name,
            category: 'meeting',
            type:
              meeting.calendarId.type === 'internal'
                ? 'meetings'
                : meeting.calendarId.provider,
            calendarId: meeting.calendarId.id,
            eventId: meeting.eventId,
            start: meeting.startDate,
            end: meeting.endDate,
            title: meeting.name,
            color: itemColor,
            data: { ...meeting, calendarId: meeting.calendarId.id },
          } as CalendarEvent,
        };
        dispatch(setNavigateTo(`/main/event/${meeting.eventId}`));
        dispatch(setCalendarEventDetailsDialog(payload));
      }}>
      <HStack spacing={0} alignItems={'flex-start'} w="100%">
        <VStack spacing={0} w="100%" alignItems={'flex-start'} gap={2}>
          {/* date and time */}
          <Text title={`${date}, ${time}`}>
            {/* date */}
            <span>{date}, </span>
            {/* time */}
            <span>{startTime}</span>
          </Text>
          {/* name */}
          <Text fontWeight={'400'} color="#00000099" title={meeting.name}>
            {meeting.name}
          </Text>
        </VStack>
        {meeting.isAnchorMeeting && <LuBookmark size={'24px'} />}
      </HStack>
      <VStack
        flex={1}
        w="100%"
        justifyContent={'space-between'}
        alignItems="center"
        isTruncated>
        <HStack gap={1} w="100%" overflow={'hidden'} flex={1}>
          {/* Location */}
          <HStack w="100%" overflow={'hidden'} flex={1} isTruncated gap={1}>
            <HiOutlineLocationMarker size={'15px'} />
            <Text
              marginLeft={'0 !important'}
              textTransform={'capitalize'}
              fontWeight={'500'}
              isTruncated
              textOverflow={'ellipsis'}
              title={meeting.location.label}>
              {meeting.location.label}
            </Text>
          </HStack>
          {/* guests */}
          {!isLoading && creatorUser && (
            <HStack
              spacing={0}
              position={'relative'}
              left={meeting.guests.length > 0 ? '8px' : '0'}>
              <AuthImage
                name={`${creatorUser.firstName}`}
                isAvatar
                path={creatorUser?.media?.find((m) => m.type === 'avatar')?.url}
                h="24px"
                w="24px"
                color={'red'}
                borderRadius="full"
                imageProps={{
                  style: {
                    backgroundColor: itemColor,
                  },
                }}
              />
              <Flex
                display={meeting.guests.length > 0 ? 'flex' : 'none'}
                transform="auto"
                translateX="-8px !important"
                borderRadius={'full'}
                w="24px"
                h="24px"
                bg={'#93C3B3'}
                p={0}
                justifyContent={'center'}
                alignItems={'center'}
                color="white"
                fontSize="12px"
                fontWeight={'400'}>
                <Text m="0 !important">+{meeting.guests.length}</Text>
              </Flex>
            </HStack>
          )}
        </HStack>
      </VStack>
    </VStack>
  );
};

export default SuppierMeetingItem;
