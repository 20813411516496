import { Flex } from '@chakra-ui/react';

import { CalendarMedium } from './components/CalendarMedium';
import EventsAndMeeting from './components/EventsAndMeeting';
import TaskList from './components/TaskList';
import Tracker from './components/Tracker';
import UpcomingEvents from './components/UpcomingEvents';

export default function Dashboard() {
  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      height="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}>
      <Flex direction="column">
        <Flex gap={5} w="100%">
          <Flex gap={5} w="100%" wrap="wrap">
            <CalendarMedium
              cardStyles={{ minH: '580px', maxH: '580px', minW: '400px' }}
            />
            <EventsAndMeeting cardStyles={{ minH: '580px' }} />
            <TaskList cardStyles={{ minH: '580px', maxH: '580px' }} />
            <Flex
              gap={5}
              flexDirection={{ base: 'column', lg: 'row' }}
              flex="1">
              <UpcomingEvents />
              <Tracker
                cardStyles={{ minH: '580px', maxH: '580px', maxW: '310px' }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
