import {
  Divider,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { format } from 'date-fns';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { LuTrash2 } from 'react-icons/lu';
import { MdOutlineMoreHoriz } from 'react-icons/md';
import useLineUp from '../hooks/useLineUp';

type LineUpProps = {
  additionalProps?: any;
};

export default function LineUp({ additionalProps }: LineUpProps) {
  const { style, ref } = useHighlightAnimation('line_up');
  const { t } = useTranslation();
  const {
    handleOpenLineUpFormDialog,
    mediaData,
    handleDeleteMedia,
    handleDownloadMedia,
  } = useLineUp();

  return (
    <Card
      ref={ref}
      {...style}
      minH="300px"
      maxHeight="620px"
      minHeight="451px"
      flex="1"
      borderRadius={{ base: 0, md: '30px' }}
      gap="20px"
      spacing={0}
      {...additionalProps}>
      <Flex alignItems="center" justify="space-between">
        <Text variant="eventPageCardTitle">{t('event.line_up')}</Text>
        <IconButton
          aria-label="edit"
          icon={<Icon as={FaPlus} color="#EEEEEE" w="10px" h="10px" />}
          variant="actionIconBlueSmall"
          onClick={handleOpenLineUpFormDialog}
          transition="all 0.2s ease-in-out"
          _hover={{
            transform: 'scale(1.1)',
          }}
        />
      </Flex>
      <Flex
        flexDirection="column"
        display={mediaData?.results?.length > 0 ? 'flex' : 'none'}
        gap="10px"
        overflow="auto">
        {mediaData?.results?.map((media, index) => (
          <Flex flexDirection="column" gap="10px" key={media.id}>
            <Flex
              flex="1"
              h="38px"
              alignItems="center"
              justifyContent="space-between">
              <Flex
                flexDirection="column"
                cursor="pointer"
                onClick={() => handleDownloadMedia(media)}>
                <Text variant="lineUpItemFileName" noOfLines={1} maxW="200px">
                  {media?.name}
                </Text>
                <Text variant="previewQuoteProductHeader">
                  {media?.createdAt
                    ? format(new Date(media.createdAt), 'd MMM, yyyy')
                    : ''}
                </Text>
              </Flex>
              <Menu>
                <MenuButton>
                  <Icon
                    as={MdOutlineMoreHoriz}
                    color="#93C3B3"
                    w="20px"
                    h="20px"
                  />
                </MenuButton>
                <MenuList
                  w="176px"
                  h="72px"
                  borderRadius="24px"
                  p="0"
                  overflow="hidden">
                  <MenuItem
                    gap="13px"
                    justifyContent="center"
                    alignItems="center"
                    onClick={() => handleDeleteMedia(media.id)}
                    h="100%">
                    <Icon as={LuTrash2} color="#FF554A" w="25px" h="25px" />
                    <Text variant="lineUpItemDelete">
                      {t('event.delete_file')}
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Divider
              bg="#E1E1E1"
              display={
                index === mediaData?.results?.length - 1 ? 'none' : 'block'
              }
            />
          </Flex>
        ))}
      </Flex>
      {mediaData?.results?.length === 0 && (
        <Flex justify="center" align="center" h="100%">
          <Text color="gray.500">{t('event.no_lineup')}</Text>
        </Flex>
      )}
    </Card>
  );
}
