import { HStack, Icon, IconButton, Text, VStack } from '@chakra-ui/react';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { IClient, IQoute } from 'services/@types';
import CustomerDetailsForm from './CustomerDetailsForm';

export default function CustomerDetails({
  handleAddCustomer,
  error,
  newQuote,
  setNewQuote,
  handleAutoCompleteContactDetails,
}: {
  handleAddCustomer: () => void;
  error: {
    customerName: boolean;
    products: boolean;
    totalPayment: boolean;
  };
  newQuote: IQoute;
  setNewQuote: React.Dispatch<React.SetStateAction<IQoute>>;
  handleAutoCompleteContactDetails: (client: IClient, key: number) => void;
}) {
  const { t } = useTranslation();

  const memoizedCustomerDetails = useMemo(() => {
    return newQuote.customerDetails;
  }, [newQuote.customerDetails]);

  return (
    <VStack alignItems="start" gap="16px">
      {memoizedCustomerDetails?.map((customer, index) => (
        <React.Fragment key={index}>
          <Text variant="createQuoteTitle">
            {t('create_quote.customer_details')}
            {index > 0 ? ` #${index + 1}` : null}
          </Text>
          <CustomerDetailsForm
            customer={customer}
            setNewQuote={setNewQuote}
            index={index}
            error={error}
            handleAutoCompleteContactDetails={handleAutoCompleteContactDetails}
          />
        </React.Fragment>
      ))}
      {memoizedCustomerDetails?.length < 2 && (
        <HStack>
          <IconButton
            borderRadius="10px"
            icon={<Icon as={FiPlus} color="white" w="30px" h="30px" />}
            onClick={handleAddCustomer}
            aria-label="add"
            w="33px"
            h="33px"
            minW="33px"
            bg="#93C3B3"
          />
          <Text>{t('create_quote.add_customer')}</Text>
        </HStack>
      )}
    </VStack>
  );
}
