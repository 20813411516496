import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { IQoute, IQuoteProductListItem, Product, } from 'services/@types';
import _productService from 'services/product.api';
import QuoteService from 'services/quote.api';

const useGetLatestQuote = (eventId: string) => {
  const [areProductsLoading, setAreProductsLoading] = useState(true);
  const [products, setProducts] = useState<
    (IQuoteProductListItem & { populatedProduct: Product })[]
  >([]);
  const { data: quote, isLoading } = useQuery<IQoute | null>({
    queryKey: [`quoteInEventPage-${eventId}`],
    queryFn: async () => {
      const response = await QuoteService.getQuotes({
        eventId: eventId,
        limit: 1,
        sortBy: 'createdAt:desc',
        status: 'accepted',
      });
      if (response.results.length > 0) {
        const quoteData = response.results[0];
        return quoteData;
      }
      return null;
    },
    retry: false,
    enabled: !!eventId,
  });

  useEffect(() => {
    if (quote) {
      const retrievePackages = async () => {
        setAreProductsLoading(true);
        const rawProducts = await Promise.all(
          quote.products.map(async (product) => {
            const populatedProduct =
              await _productService.getProduct<Product>(
                product.productId,
                {
                  populate: 'subItems',
                },
              );

            return {
              ...product,
              populatedProduct,
            };
          }),
        );
        setProducts(rawProducts);
        setAreProductsLoading(false);
      };
      retrievePackages();
    }
  }, [quote]);

  const loading = useMemo(
    () => isLoading || areProductsLoading,
    [isLoading, areProductsLoading],
  );

  // Calculate summary based on products
  const summary = useMemo(() => {
    if (products.length === 0 || !quote)
      return { sum: '0', vat: '0', total: '0' };
    const sum = quote.products.reduce(
      (acc: number, product: IQuoteProductListItem) => acc + product.total,
      0,
    );
    const vat = sum * 0.17; // Example VAT calculation
    const total = sum + vat;
    return {
      sum: `${sum} ${quote.currency}`,
      vat: `${vat} ${quote.currency}`,
      total: `${total} ${quote.currency}`,
    };
  }, [products, quote]);

  return { isLoading: loading, quote, products, summary };
};

export default useGetLatestQuote;
