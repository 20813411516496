import { Checkbox, MenuList } from '@chakra-ui/react';

import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { IQoute } from 'services/@types';
import { VAT_PERCENTAGE } from 'variables/currency';

export const ProductVatCheckbox = memo(
  ({
    product,
    index,
    setNewQuote,
  }: {
    product: any;
    index: number;
    setNewQuote: React.Dispatch<React.SetStateAction<IQoute>>;
  }) => {
    const { t } = useTranslation();
    return (
      <Flex
        alignItems="center"
        gap="4px"
        w={{ base: '100%', md: 'fit-content' }}
        justifyContent={{ base: 'space-between', md: 'flex-start' }}>
        <Checkbox
          variant="roundBrand"
          isChecked={product[index]?.isVatIncluded}
          onChange={(e) => {
            setNewQuote((prev) => ({
              ...prev,
              products: prev.products.map((p, key) =>
                key === index
                  ? {
                      ...p,
                      isVatIncluded: e.target.checked,
                      total: e.target.checked
                        ? p.total + p.total * VAT_PERCENTAGE
                        : p.price * p.amount,
                    }
                  : p,
              ),
            }));
          }}
          h="50px"
          alignItems="center">
          <Text variant="createQuoteCheckboxText">
            {t('create_quote.vat_17')}
          </Text>
        </Checkbox>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label=""
            icon={<Icon as={FiMoreHorizontal} color="#93C3B3" />}
            variant="ghost"
            h="50px"
          />
          <MenuList>
            <MenuItem
              onClick={() => {
                setNewQuote((prev) => ({
                  ...prev,
                  products: prev.products.filter((p, key) => key !== index),
                }));
              }}>
              {t('create_quote.remove_product')}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    );
  },
);
