// Chakra imports
import { Flex, Switch, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
// Custom components
export default function Default(props: {
  id: string;
  label?: string;
  isChecked?: boolean;
  fontSize?: string;
  labelColor?: string;
  onChange?: (event?: any) => void;
  desc?: string;
  textWidth?: string | number;
  reversed?: boolean;
  [x: string]: any;
  labelProps?: any;
  colorScheme?: string;
  wrapperProps?: any;
}) {
  const {
    id,
    label,
    labelColor,
    isChecked,
    onChange,
    desc,
    textWidth,
    reversed,
    fontSize,
    labelProps,
    colorScheme,
    wrapperProps,
    ...rest
  } = props;
  const textColorPrimary = useColorModeValue('#00000099', 'white');
  const { i18n } = useTranslation();
  return (
    <Flex
      w="100%"
      fontWeight="500"
      {...rest}
      align="center"
      justify={'end'}
      pb={1}>
      {reversed ? (
        <Flex align="center" borderRadius="16px" {...wrapperProps}>
          {onChange ? (
            <Switch
              isChecked={isChecked}
              id={id}
              dir={i18n.dir()}
              colorScheme={colorScheme || 'brand'}
              size={rest.size || 'md'}
              onChange={onChange}
            />
          ) : (
            <Switch
              dir={i18n.dir()}
              id={id}
              colorScheme={colorScheme || 'brand'}
              size={rest.size || 'md'}
            />
          )}
          <Text
            color={labelColor || textColorPrimary}
            fontSize={fontSize || 'md'}
            fontWeight="500"
            w={textWidth || 'auto'}
            {...labelProps}>
            {label}
          </Text>
        </Flex>
      ) : (
        <Flex justify="space-between" align="center" gap={'12px'}>
          <Text
            color={labelColor || textColorPrimary}
            fontSize={fontSize || 'md'}
            fontWeight="500"
            {...labelProps}>
            {label}
          </Text>
          {onChange ? (
            <Switch
              isChecked={isChecked}
              id={id}
              dir={i18n.dir()}
              variant="brand"
              size={rest.size || 'md'}
              onChange={(ev: any) => {
                console.log('ev ->', ev);
                onChange?.(ev);
              }} 
            />
          ) : (
            <Switch
              dir={i18n.dir()}
              id={id}
              variant="brand"
              size={rest.size || 'md'}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
}
