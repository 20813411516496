// Chakra imports
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  HStack,
  Icon,
  Show,
  TabList,
  Tab,
  Tabs,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
// Assets
import {
  MdChevronLeft,
  MdChevronRight,
  MdOutlinePersonSearch,
  MdOutlineSearchOff,
} from 'react-icons/md';
// Custom components
import Card from 'components/card/Card';
import DropdownField from 'components/fields/DropdownField';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Business, Supplier } from 'services/@types';
import { AddSupplierModal } from 'views/main/managment/suppliers/components/AddSupplierModal';
import SupplierDetails from 'views/main/managment/suppliers/components/SupplierDetails';
// import Conversations from 'views/admin/main/others/messages/components/Conversations';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import {
  closeAlertDialog,
  openAlertDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import {
  deleteSupplier,
  fetchSuppliers,
  setCurrentSupplier,
} from 'contexts/redux/supplier/supplierSlice';
import { AnimatePresence, motion } from 'framer-motion';
import AskForQuoteModal from './components/AskForQuoteModal';
import NoSuppliers from './components/NoSuppliers';
import { SupplierFoundModal } from './components/SupplierFoundModal';
import { SupplierItemComponent } from './components/SupplierItemComponent';
import { useFilteredSuppliers } from './hooks/useFilteredSuppliers';
import { LuSearchSlash, LuSearchX } from 'react-icons/lu';
export default function Suppliers() {
  const dispatch = useDispatch<any>();

  // Chakra Color Mode
  let sidebarBackgroundColor = useColorModeValue('white', 'navy.800');
  const searchIconColor = useColorModeValue('gray.700', 'white');
  const {
    filteredSuppliers,
    filteringCategories,
    setFilteringCategories,

    suppliers,
    setSearchQuery,
    setFilterFavorites,
    setFilterMyPhonebook,
  } = useFilteredSuppliers();

  const { businessCategories, currentSupplier } = useSelector(
    (state: RootState) => ({
      businessCategories: state.businessCategories.businessCategories,
      currentSupplier: state.suppliers.currentSupplier,
    }),
  );
  // Ellipsis modals
  const [, setFoundBusiness] = useState<any>(null);
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const [currentEditableSupplier, setCurrentEditableSupplier] =
    useState<Partial<Supplier>>(null);
  // Chakra Color Mode

  const {
    isOpen: isAddSupplierOpen,
    onOpen: onOpenAddSupplier,
    onClose: onAddSupplierClosed,
  } = useDisclosure();
  const {
    isOpen: isSupplierFoundOpen,
    onOpen: onOpenSupplierFound,
    onClose: onCloseSupplierFound,
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (currentSupplier || !isDesktop) {
      return;
    } else if (suppliers.length > 0) {
      dispatch(setCurrentSupplier(suppliers[0]));
    }
  }, [currentSupplier, dispatch, isDesktop, suppliers]);

  const handleOnEditSupplier = useCallback(
    (supplier: Supplier) => {
      const updatedSupplier = suppliers.find((s) => s.id === supplier.id);
      setCurrentEditableSupplier(updatedSupplier);
      onOpenAddSupplier();
    },
    [onOpenAddSupplier, suppliers],
  );
  const handleOnDeleteSupplier = useCallback(
    (supplier: Supplier) => {
      console.log('delete supplier ->', supplier);
      dispatch(
        openAlertDialog({
          // title: t('suppliers.modals.delete_supplier.title'),
          content: t('suppliers.modals.delete_supplier.description'),
          onConfirm: () => {
            console.log('delete supplier confirmed ->', supplier);
            dispatch(deleteSupplier(supplier.id));
            dispatch(closeAlertDialog());
            toast({
              variant: 'main',
              title: t('suppliers.toast.supplier_deleted'),
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            dispatch(setCurrentSupplier(null));
          },
          onClose: () => {
            dispatch(closeAlertDialog());
          },
        }),
      );
    },
    [dispatch, t, toast],
  );

  const handleAddSupplierClosed = useCallback(() => {
    onAddSupplierClosed();
    setCurrentEditableSupplier(null);
  }, [onAddSupplierClosed]);

  const handleCategorySelected = useCallback(
    (selected: { id: string; label: string }) => {
      if (selected.id === 'all') {
        setFilteringCategories([]);
        return;
      }
      const newCategories = [...(filteringCategories || [])];
      if (!newCategories.includes(selected?.id)) {
        newCategories.push(selected?.id as string);
      } else {
        newCategories.splice(newCategories.indexOf(selected?.id as string), 1);
      }

      setFilteringCategories(newCategories);
      dispatch(setCurrentSupplier(null));
    },
    [filteringCategories, setFilteringCategories, dispatch],
  );
  const suppliersBusinessCategories = useMemo(() => {
    return [
      { label: t('suppliers.all_categories'), id: 'all' },
      ...businessCategories?.map((category) => ({
        id: category.id,
        label: t(`categories.${category.name}`),
      })),
    ];
  }, [businessCategories, t]);
  useEffect(() => {
    dispatch(fetchSuppliers({}));
  }, [dispatch]);
  const handleTabSelected = useCallback(
    (index: number) => {
      console.log('tab selected ->', index);
      switch (index) {
        case 0:
          setFilterFavorites(false);
          setFilterMyPhonebook(false);
          break;
        case 1:
          setFilterFavorites(false);
          setFilterMyPhonebook(true);
          break;
        case 2:
          setFilterFavorites(true);
          setFilterMyPhonebook(false);
          break;
      }
    },
    [setFilteringCategories],
  );

  if (suppliers.length === 0) {
    return (
      <>
        <NoSuppliers
          onOpenAddSupplier={onOpenAddSupplier}
          filteringCategories={filteringCategories}
        />
        <AddSupplierModal
          isOpen={isAddSupplierOpen}
          editableSupplier={currentEditableSupplier}
          onFoundExistingSupplier={(business: Business) => {
            console.log('found business ->', business);
            onOpenSupplierFound();
            setFoundBusiness(business);
          }}
          onClose={handleAddSupplierClosed}
        />
        <SupplierFoundModal
          isOpen={isSupplierFoundOpen}
          onClose={onCloseSupplierFound}
        />
      </>
    );
  }

  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      flexDirection="column"
      h="100%"
      pb={{ base: '0px', md: '23px' }}
      gap={4}>
      <Flex
        w="100%"
        justify={'flex-end'}
        align={{ base: 'center', md: 'flex-end' }}
        px={{ base: '26px', md: '0px' }}
        gap={'16px'}>
        <DropdownField
          hideLabel
          flex={1}
          options={suppliersBusinessCategories}
          placeholder={
            filteringCategories.length > 0
              ? t('suppliers.categories', {
                  count: filteringCategories.length,
                })
              : t('suppliers.all_categories')
          }
          onSelected={handleCategorySelected}
          name="category"
          maxW={540}
          selectedOptions={filteringCategories}
        />
        {/* <Spacer bg="green" /> */}
        <Flex flex={1} justify={'end'} w={{ base: 'auto', md: '320px' }}>
          <Button
            onClick={onOpenAddSupplier}
            variant={'h1cta'}
            w={{ base: '100%', md: '320px' }}
            flex={{ base: 1, md: 'unset' }}>
            {t('suppliers.add_supplier')}
          </Button>
        </Flex>
      </Flex>

      <Flex
        h="100%"
        flexDirection={{ base: 'column', lg: 'row' }}
        gap={{ base: '20px', xl: '20px' }}
        pb={{ base: '0px', md: '33px' }}>
        <Card
          p="0px"
          h="100%"
          display={'flex'}
          overflow="scroll"
          px="0px"
          borderRadius={{ base: '0px', md: '30px' }}
          pt="30px"
          pb="30px"
          w="100%"
          maxW={{ base: '100%', lg: '540px' }}>
          {/* <Conversations onOpenMessages={onOpen} /> */}
          <Flex px={6} pb={6}>
            <SearchBar
              placeholder={t('suppliers.search')}
              onChange={(e: any) => setSearchQuery(e.target.value)}
              w="100%"
            />
          </Flex>
          <Flex mx={6} mb={2}>
            <Tabs
              variant="task"
              isLazy={true}
              height="100%"
              overflowY="auto"
              onChange={(index: number) => handleTabSelected(index)}>
              <TabList gap={9}>
                <Tab>{t('suppliers.tabs.all')}</Tab>
                <Tab>{t('suppliers.tabs.my_phonebook')}</Tab>
                <Tab>{t('suppliers.tabs.favorites')}</Tab>
              </TabList>
            </Tabs>
          </Flex>
          {filteredSuppliers?.map((supplier) => (
            <SupplierItemComponent
              supplier={supplier}
              onOpen={onOpen}
              currentSupplier={currentSupplier}
              key={supplier.id}
            />
          ))}
          <VStack
            display={filteredSuppliers.length === 0 ? 'flex' : 'none'}
            py={20}>
            <HStack>
              <Icon as={LuSearchX} opacity={0.6} fontSize="24px" />
              <Text opacity={0.6} fontWeight="semibold">
                {t('suppliers.no_suppliers_found')}
              </Text>
            </HStack>
          </VStack>
        </Card>
        <Show above="md">
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ opacity: 0, scale: 0.98 }}
              animate={{ opacity: 1, scale: 1 }}
              style={{
                width: '100%',
                height: '100%',
              }}
              exit={{ opacity: 0, scale: 0.98 }}
              transition={{ duration: 0.15 }}
              key={currentSupplier?.id}>
              <Card
                display={{
                  base: 'none',
                  lg: currentSupplier ? 'flex' : 'none',
                }}
                overflowY="auto"
                px="0px"
                w="100%"
                h="100%"
                pt={{ base: '20px', md: '40px' }}>
                <SupplierDetails
                  onDeleteSupplier={handleOnDeleteSupplier}
                  onEditSupplier={handleOnEditSupplier}
                  supplier={currentSupplier}
                  status="active"
                  name="Roberto Michael"
                />
              </Card>
            </motion.div>
          </AnimatePresence>
        </Show>
        <Show below="lg">
          <Drawer
            isOpen={isOpen}
            onCloseComplete={() => {
              setCurrentSupplier(null);
            }}
            placement={i18n.dir() === 'rtl' ? 'right' : 'left'}
            onClose={() => {
              onClose();
            }}>
            <DrawerContent
              maxW="full"
              mt={{
                sm: 180,
                md: 200,
              }}
              padding={0}
              borderRadius="0px"
              bg={sidebarBackgroundColor}>
              <HStack
                position="absolute"
                h={{ sm: 74, md: 90, lg: 84 }}
                align="center"
                ps={{ sm: 2, md: 0, lg: 0 }}>
                <DrawerCloseButton
                  boxShadow="unset !important"
                  _hover={{ bg: 'transparent' }}
                  position={'relative'}
                  alignSelf="center"
                  color="secondaryGray.700"
                  right={0}
                  zIndex={1}
                  top={0}>
                  <Icon
                    as={i18n.dir() === 'rtl' ? MdChevronRight : MdChevronLeft}
                    color={searchIconColor}
                    w="28px"
                    h="28px"
                  />
                </DrawerCloseButton>
              </HStack>
              <DrawerBody px="0px">
                <SupplierDetails
                  onDeleteSupplier={handleOnDeleteSupplier}
                  onEditSupplier={handleOnEditSupplier}
                  supplier={currentSupplier}
                  status="active"
                  name="Roberto Michael"
                />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Show>
      </Flex>
      <AddSupplierModal
        isOpen={isAddSupplierOpen}
        editableSupplier={currentEditableSupplier}
        onFoundExistingSupplier={(business: Business) => {
          console.log('found business ->', business);
          onOpenSupplierFound();
          setFoundBusiness(business);
        }}
        onClose={handleAddSupplierClosed}
      />
      <SupplierFoundModal
        isOpen={isSupplierFoundOpen}
        onClose={onCloseSupplierFound}
      />
      <AskForQuoteModal />
    </Flex>
  );
}
