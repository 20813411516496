import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import Dropzone from 'components/dropzone';
import { closeLineUpFormDialog } from 'contexts/redux/dialog/dialogsSlice';
import { createMediaItem } from 'contexts/redux/mediaItem/mediaItemSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { MediaItem, UrlItem } from 'services/@types';
import UploadService from 'services/upload.api';

export default function LineUpFormDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const client = useQueryClient();
  const [file, setFile] = useState<UrlItem | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const dialog = useSelector(
    (state: RootState) => state.dialogs.lineUpFormDialog,
  );

  const onClose = useCallback(() => {
    dispatch(closeLineUpFormDialog());
    setFile(null);
    setIsUploading(false);
  }, [dispatch]);

  const handleSaveClicked = useCallback(async () => {
    const mediaItem: MediaItem = {
      albumID: dialog?.item?.lineUpId as string,
      media: [
        { url: file?.url, type: file?.type, displayName: file?.displayName },
      ],
      type: ['image', 'video', 'document', 'audio'].includes(file?.type)
        ? (file?.type as MediaItem['type'])
        : 'other',
      tags: [],
      privacy: 'private',
      name: file?.displayName,
    };
    const response = await dispatch(createMediaItem(mediaItem));
    if (response) {
      client.invalidateQueries({
        queryKey: ['lineUp-media', dialog?.item?.lineUpId],
      });
      toast({
        title: t('event.dialog_line_up.success'),
        variant: 'main',
        position: 'top-right',
      });
    }

    onClose();
  }, [onClose, file, dialog?.item?.lineUpId, client, dispatch, toast, t]);

  const handleUploadCompleted = useCallback((url: UrlItem) => {
    console.log('upload completed', url);
    setIsUploading(false);
    setFile(url);
  }, []);

  const handleRemoveFile = useCallback(async () => {
    if (!file || !dialog?.item?.lineUpId) return;
    const [target, targetId, access, fileId] = file.url.split('/');
    await UploadService.deleteUpload({
      target,
      targetId,
      access,
      fileId,
    });
    setFile(null);
  }, [file, dialog?.item?.lineUpId]);

  return (
    <Modal
      isOpen={!!dialog}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent gap="16px" p="18px 15px" borderRadius="16px">
        <Flex justifyContent="space-between" gap={2} w="100%">
          <Text variant="eventPageCardTitle">{t('event.line_up')}</Text>
          <Icon
            as={FiX}
            cursor="pointer"
            onClick={onClose}
            color="#939393"
            transition="all 0.2s ease-in-out"
            _hover={{
              transform: 'scale(1.1)',
            }}
          />
        </Flex>
        <Text variant="eventTabButtonTitle">
          {t('event.dialog_line_up.desc')}
        </Text>
        <Flex h="70px" gap={2} w="100%" mb={0}>
          {!file && (
            <Dropzone
              labelSize="14px"
              maxH="68px"
              goldenUploadLabel={t('event.dialog_line_up.upload_file')}
              flex={1}
              cursor="pointer"
              onUploadCompleted={handleUploadCompleted}
              dropzoneOptions={{
                accept: [
                  'image/*',
                  'video/*',
                  'audio/*',
                  'application/pdf',
                  'text/plain',
                ],
                multiple: false,
              }}
              onStartUpload={() => setIsUploading(true)}
              uploadBarStyle="line"
              uploadKey="attachment"
              access="restricted"
              target="album"
              targetId={dialog?.item?.lineUpId || ''}
            />
          )}
          {file && (
            <Flex alignItems="center" gap={2}>
              <Text variant="lineUpFormDialogFileName">{file.displayName}</Text>
              <Icon
                as={FiX}
                cursor="pointer"
                onClick={handleRemoveFile}
                color="#939393"
                transition="all 0.2s ease-in-out"
                _hover={{
                  transform: 'scale(1.1)',
                }}
              />
            </Flex>
          )}
        </Flex>
        <Flex justify="flex-end">
          <Button
            w="50%"
            variant="h1cta"
            isLoading={isUploading}
            disabled={!file}
            onClick={handleSaveClicked}>
            {t('event.dialog_line_up.save')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
