// Chakra imports
import {
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconProps,
  Image,
  Progress,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { CloudUploadIcon } from 'components/icons/CloudUploadIcon';
import useUploadFile from 'hooks/useUploadFile';
import { useCallback, useEffect, useMemo, useState } from 'react';
// Assets

// import cloudUploadIcon from 'assets/svg/gallery/icon-upload-cloud.svg';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { MdCheck } from 'react-icons/md';
import { UrlItem } from 'services/@types';

export interface DropzoneProps extends FlexProps {
  goldenUploadLabel?: string;
  uploadLabel?: string;
  labelSize?: string;
  uploadIcon?: any;
  currentImage?: string;
  dropzoneOptions?: DropzoneOptions;
  uploadKey?: string;
  disabled?: boolean;
  initialProgress?: number;
  error?: string;
  target?: string;
  targetId?: string;
  uploadBarStyle?: 'fill' | 'line';
  imageSize?: 'original' | 'sm' | 'md' | 'lg';
  access?: string;
  onUploadCompleted?: (url: UrlItem) => void;
  onUploadError?: () => void;
  onStartUpload?: () => void;
  currentImageProps?: any;
  checkIconProps?: IconProps;
}
const Dropzone: React.FC<DropzoneProps> = ({
  currentImage,
  goldenUploadLabel,
  uploadLabel,
  labelSize,
  disabled,
  imageSize,
  uploadKey,
  target,
  targetId,
  initialProgress,
  access,
  error,
  uploadBarStyle = 'fill',
  onStartUpload,
  onUploadCompleted,
  onUploadError,
  currentImageProps,
  checkIconProps,
  ...props
}) => {
  //   console.log('Dropzone -> ', content, progress, imageUrl, error);

  const bg = useColorModeValue('white', 'navy.700');
  const { t } = useTranslation();
  const borderColor = useColorModeValue('gray.500', 'whiteAlpha.100');
  const [uploadError, setUploadError] = useState<any>();
  const [progress, setProgress] = useState<any>(initialProgress || -1);
  const { uploadFile } = useUploadFile();
  const isUploadInProgress = useMemo(
    () => progress > -1 && progress < 100,
    [progress],
  );
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (onStartUpload) {
        onStartUpload();
      }
      setUploadError(undefined);
      setProgress(0);
      const file = acceptedFiles[0];
      uploadFile(
        file,
        target,
        targetId,
        access,
        (progress) => setProgress(progress),
        (upload, progress) => {
          setProgress(100);
          onUploadCompleted({
            type: uploadKey,
            url: upload.filePath,
            displayName: upload.fileName,
          });
        },
        (error: any) => {
          setUploadError(error?.message);
          if (onUploadError) {
            onUploadError();
          }
        },
      );
    },
    [
      onStartUpload,
      uploadFile,
      target,
      targetId,
      access,
      onUploadCompleted,
      uploadKey,
      onUploadError,
    ],
  );
  const { getRootProps, getInputProps } = useDropzone({
    ...props.dropzoneOptions,
    onDrop: handleDrop,
    disabled: disabled || (progress > -1 && progress < 100),
  });

  useEffect(() => {
    setProgress(-1);
  }, [currentImage, disabled, uploadKey]);

  return (
    <Flex
      align="center"
      justify="center"
      bg={bg}
      border={
        isUploadInProgress || disabled || currentImage ? 'none' : '1px dashed'
      }
      position={'relative'}
      borderColor={borderColor}
      borderRadius="16px"
      _hover={{
        border: isUploadInProgress || disabled ? 'none' : '1px dashed',
      }}
      overflow="hidden"
      transition={'all 0.2s ease-in-out'}
      w="100%"
      h="max-content"
      cursor={disabled ? 'not-allowed' : 'pointer'}
      minH="100%"
      {...getRootProps({ className: !progress ? undefined : 'dropzone' })}
      {...props}>
      <input {...getInputProps()} />

      <Flex
        position="absolute"
        display={progress > -1 ? 'flex' : 'none'}
        top="0"
        left="0"
        right="0"
        bottom="0"
        borderRadius={'2xl'}
        zIndex={1}
        px={3}
        align="center"
        overflow="hidden"
        justify={uploadBarStyle === 'fill' ? 'start' : 'center'}
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: 'inherit',
          backgroundColor: 'gray.200', // Red background color
          zIndex: -2,
        }}
        _after={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          overflow: 'hidden',
          bottom: 0,
          borderRadius: '0px',
          bg:
            uploadBarStyle === 'line'
              ? undefined
              : `linear-gradient(90deg, #93C3B3 ${progress}%, rgba(76, 175, 80, 0) ${progress}%)`,
          width: `${progress}%`,
          zIndex: -1,
          transition: 'width 0.1s linear',
        }}>
        <VStack>
          <HStack justifyContent={'space-between'}>
            <Text opacity={0.5} flex={1} fontSize={'md'}>
              {progress === 100
                ? t('uploads.got_it')
                : t('uploads.uploading', { progress })}
            </Text>
            <Icon
              as={MdCheck}
              opacity={0.5}
              fontWeight="bold"
              fontSize="md"
              display={progress === 100 ? 'block' : 'none'}
              {...checkIconProps}
            />
          </HStack>
          <Progress
            isAnimated
            value={progress}
            w="100%"
            variant="uploadLine"
            display={
              uploadBarStyle === 'line' && progress > -1 ? 'block' : 'none'
            }
          />
        </VStack>
      </Flex>

      {error && (
        <Flex
          position="absolute"
          top="0"
          left="0"
          pointerEvents={'none'}
          right="0"
          bottom="0"
          borderRadius={'2xl'}
          bg="rgba(255, 255, 255, 0.5)"
          zIndex={1}
          align="center"
          justify="center">
          <Text color="brand.800" fontWeight={'bold'} fontSize={'md'}>
            {error}
          </Text>
        </Flex>
      )}

      <Flex
        width="100%"
        height="100%"
        display={progress > -1 ? 'none' : 'block'}>
        {currentImage && (
          <AuthImage
            path={currentImage}
            w="100%"
            h={'100%'}
            size={imageSize || 'original'}
            borderRadius={'16px'}
            {...currentImageProps}
          />
        )}
      </Flex>

      <Flex
        position="absolute"
        flexDirection={'column'}
        align="center"
        w="100%"
        display={disabled || isUploadInProgress ? 'none' : 'flex'}
        opacity={!currentImage ? 1 : 0}
        _hover={{ opacity: 0.6, transform: 'scale(1.1)' }}
        transition="all 0.2s ease-in-out"
        h="100%"
        p={2}
        // bg="#ffffff80"
        justify={'center'}>
        {props.uploadIcon}
        {/* <Image
          display={props.uploadIcon ? 'none' : 'block'}
          w={'20px'}
          h={'20px'}
          src={cloudUploadIcon}
        /> */}
        <CloudUploadIcon
          color={checkIconProps?.color as string}
          iconProps={{
            display: props.uploadIcon ? 'none' : 'block',
            fontSize: 20,
            ...checkIconProps,
          }}
        />
        {/* <Icon
          display={props.uploadIcon ? 'none' : 'block'}
          as={LiaCloudDownloadAltSolid}
          fontSize={20}
          color={'brand.900'}
        /> */}
        <Text
          color="brand.900"
          textDecoration={'underline'}
          fontSize={labelSize || '12px'}>
          {goldenUploadLabel}
        </Text>
        <Text
          fontSize={labelSize || '16px'}
          wordBreak={'break-word'}
          color="secondaryGray.500"
          textAlign={'center'}
          textDecoration={'underline'}
          fontWeight={'normal'}>
          {uploadLabel}
        </Text>
      </Flex>
      <Flex
        position="absolute"
        w="100%"
        h="100%"
        display={uploadError ? 'flex' : 'none'}
        justify="center"
        align="center"
        px={2}>
        <Text color="red.400" fontWeight={'semibold'} fontSize="xs">
          {uploadError}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Dropzone;
