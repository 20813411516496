import {
  Box,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import addNoteIcon from 'assets/svg/event/add-note-icon.svg';
import Card from 'components/card/Card';
import { setAddBriefNoteDialog } from 'contexts/redux/dialog/dialogsSlice';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useGetBriefNotes } from 'queries/briefNote';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuMoreHorizontal } from 'react-icons/lu';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BriefItem } from './BriefItem';

export const ClientBrief = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id: eventId } = useParams();

  const { style, ref } = useHighlightAnimation('event_brief');
  // Get brief notes
  const { data: briefNotesData, isLoading } = useGetBriefNotes({
    eventId,
    limit: 100,
  });

  // Add state for temporary edits
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const handleAddBriefNote = useCallback(() => {
    if (!eventId) return;

    dispatch(
      setAddBriefNoteDialog({
        item: { eventId, title: 'New Note', description: '' },
      }),
    );
    onClose();
  }, [eventId, dispatch, onClose]);

  const handleEditStart = useCallback(
    (item: { id: string; title: string; description: string }) => {
      dispatch(
        setAddBriefNoteDialog({
          item,
        }),
      );
    },
    [dispatch],
  );

  if (!eventId) {
    return (
      <Box
        px="4"
        py="6"
        bg="white"
        width="100%"
        rounded={{ base: '0', md: '30px' }}
        shadow="sm"
        w="full">
        <Text color="gray.500" textAlign="center">
          {t('event.brief.brief_not_found')}
        </Text>
      </Box>
    );
  }

  return (
    <Card
      ref={ref}
      {...style}
      gridColumn={{ base: 'unset', lg: 'span 2' }}
      w="100%"
      maxHeight="620px"
      borderRadius={{ base: 0, md: '30px' }}
      gap="20px"
      overflow="auto"
      position="relative">
      <Flex justify="space-between" align="center" mb="5">
        <Text variant="eventPageCardTitle">{t('event.brief.event_brief')}</Text>
        <Menu isOpen={isOpen} onClose={onClose}>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<LuMoreHorizontal size={24} />}
            variant="ghost"
            onClick={onOpen}
          />
          <MenuList minW={140} borderRadius={'2xl'}>
            <MenuItem
              icon={<Image src={addNoteIcon} />}
              color="blackAlpha.600"
              onClick={handleAddBriefNote}>
              {t('event.brief.add_note')}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <VStack spacing="5" align="stretch" minH="100px">
        {isLoading ? (
          <Flex justify="center" align="center" h="100px">
            <Spinner color="gray.500" />
          </Flex>
        ) : briefNotesData?.results?.length ? (
          briefNotesData.results.map((item) => (
            <BriefItem
              key={item.id}
              id={item.id}
              item={item}
              onEdit={() => handleEditStart(item)}
            />
          ))
        ) : (
          <Flex
            justify="center"
            align="center"
            h="100px"
            color="gray.500"
            textAlign="center">
            <Text>No notes found. Click "Add note" to create one.</Text>
          </Flex>
        )}
      </VStack>
    </Card>
  );
};
