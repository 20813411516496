import { Flex, Hide, Text, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import InputAutocompleteField from 'components/fields/InputAutocompleteField';
import InputField from 'components/fields/InputField';
import { formatNumber } from 'helpers/formatNumber';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Business, IQoute, Product } from 'services/@types';
import ProductService from 'services/product.api';
import { EXCHANGE_RATE_FROM_ILS_TO_USD } from 'variables/currency';
import { AddProductModal } from 'views/main/managment/products/components/AddProductModal';
import { ProductVatCheckbox } from './ProductVatCheckbox';

export default function ProductItem({
  product,
  index,
  setNewQuote,
  products,
  handleSelectedProduct,
  currencyIcon,
  error,
  currency,
}: {
  product: any;
  index: number;
  setNewQuote: React.Dispatch<React.SetStateAction<IQoute>>;
  products: { value: string; label: string }[];
  handleSelectedProduct: (e: any, index: number) => void;
  currencyIcon: string;
  error: {
    products: boolean;
    totalPayment: boolean;
  };
  currency: string;
}) {
  const { t } = useTranslation();
  const [showOptions, setShowOptions] = useState(true);
  const [currentEditableProduct, setCurrentEditableProduct] =
    useState<Partial<Product>>(null);
  const {
    isOpen: isAddProductOpen,
    onOpen: onOpenAddProduct,
    onClose: onAddProductClosed,
  } = useDisclosure();

  const handleAddProductClosed = useCallback(() => {
    onAddProductClosed();
    setCurrentEditableProduct(null);
  }, [onAddProductClosed]);

  const { data: productData } = useQuery({
    queryKey: ['product', product.productId],
    queryFn: () => ProductService.getProduct(product.productId),
    enabled: !!product.productId,
  });

  // handle currency change
  useEffect(() => {
    if (currency === 'ILS') {
      setNewQuote((prev) => ({
        ...prev,
        products: prev.products.map((p) =>
          p?.productId === productData?.id
            ? {
                ...p,
                price: productData?.price,
                total: productData?.price * p.amount,
              }
            : { ...p },
        ),
      }));
    }

    if (currency === 'USD') {
      setNewQuote((prev) => ({
        ...prev,
        products: prev.products.map((p) =>
          p?.productId === productData?.id
            ? {
                ...p,
                price: productData?.price * EXCHANGE_RATE_FROM_ILS_TO_USD,
                total:
                  productData?.price * EXCHANGE_RATE_FROM_ILS_TO_USD * p.amount,
              }
            : { ...p },
        ),
      }));
    }
  }, [currency, productData?.price]);

  return (
    <Flex wrap="wrap" gap="12px" width="100%" alignItems="end">
      {showOptions && (
        <Hide above="md">
          <ProductVatCheckbox
            product={product}
            index={index}
            setNewQuote={setNewQuote}
          />
        </Hide>
      )}
      <InputAutocompleteField
        label={t('create_quote.description')}
        placeholder={t('create_quote.description')}
        maxWidth={{ base: '100%', md: '256px' }}
        w={{ base: '100%', md: '256px' }}
        value={product.description}
        options={products}
        onSelected={(e: any) => {
          handleSelectedProduct(e, index);
        }}
        borderColor={error.products ? 'error.100' : 'gray.200'}
        onChange={(e) => {
          setNewQuote((prev) => ({
            ...prev,
            products: prev.products.map((p, key) =>
              key === index ? { ...p, description: e.target.value } : p,
            ),
          }));
        }}
        onOptionsRendered={(options) => {
          console.log('options...', options);
          const showOptions =
            options?.length === 0 && product?.description?.length > 0;
          setShowOptions(!showOptions);
        }}
      />
      {showOptions && (
        <InputField
          label={t('create_quote.quantity')}
          placeholder={t('create_quote.quantity')}
          maxWidth={{ base: '100%', md: '115px' }}
          w={{ base: '100%', md: '115px' }}
          type="number"
          min={1}
          value={product.amount}
          onChange={(e) =>
            setNewQuote((prev) => ({
              ...prev,
              products: prev.products.map((p, key) =>
                key === index
                  ? {
                      ...p,
                      amount: parseInt(e.target.value),
                      total: p.price * parseInt(e.target.value),
                    }
                  : p,
              ),
            }))
          }
        />
      )}
      {showOptions && (
        <InputField
          label={t('create_quote.price')}
          placeholder={t('create_quote.price')}
          maxWidth={{ base: '100%', md: '115px' }}
          w={{ base: '100%', md: '115px' }}
          type="number"
          value={product.price}
          onChange={(e) =>
            setNewQuote((prev) => ({
              ...prev,
              products: prev.products.map((p, key) =>
                key === index
                  ? {
                      ...p,
                      price: parseInt(e.target.value),
                      total: p.price * parseInt(e.target.value),
                    }
                  : p,
              ),
            }))
          }
          iconLeft={<Text variant="currency">{currencyIcon}</Text>}
        />
      )}
      {showOptions && (
        <InputField
          label={t('create_quote.total_price')}
          placeholder={t('create_quote.total_price')}
          maxWidth={{ base: '100%', md: '125px' }}
          w={{ base: '100%', md: '125px' }}
          bg="#F6F6F6"
          // type="number"
          asNumber={true}
          format="0,0.00"
          borderColor="#F6F6F6"
          value={product.total ? formatNumber(product.total) : 0}
          // todo: handle total price change if needed
          // onChange={(e) =>
          //   setNewQuote((prev) => ({
          //     ...prev,
          //     products: prev.products.map((p, key) =>
          //       key === index ? { ...p, total: parseInt(e.target.value) } : p,
          //     ),
          //   }))
          // }
        />
      )}
      {showOptions && (
        <Hide below="md">
          <ProductVatCheckbox
            product={product}
            index={index}
            setNewQuote={setNewQuote}
          />
        </Hide>
      )}
      {!showOptions && (
        <Flex alignItems="center" h="50px">
          <Text variant="createQuoteCheckboxText">
            {t('create_quote.no_product')}
          </Text>
          <Text
            variant="addNewProduct"
            cursor="pointer"
            onClick={onOpenAddProduct}>
            {t('create_quote.add_new_product')}
          </Text>
        </Flex>
      )}
      <AddProductModal
        isOpen={isAddProductOpen}
        editableProduct={currentEditableProduct}
        onFoundExistingProduct={(business: Business) => {
          console.log('found business ->', business);
        }}
        onClose={handleAddProductClosed}
      />
    </Flex>
  );
}
