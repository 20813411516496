import { useToast } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { createClient, updateClient } from 'contexts/redux/client/clientSlice';
import { createInvite } from 'contexts/redux/invite/inviteSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IClient } from 'services/@types';

const requiredFields: (keyof IClient)[] = [
  'firstName',
  'lastName',
  'phone',
  'email',
];

export default function useClientForm({
  onClose,
}: {
  onClose: (callback?: () => void) => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch<any>();
  const { editClient } = useSelector((state: RootState) => state.clients);
  const [newClient, setNewClient] = useState<Partial<IClient>>();
  const [errors, setErrors] = useState<string[]>([]);
  const [isInviteToBrilliant, setIsInviteToBrilliant] =
    useState<boolean>(false);
  const myBusiness = useMyBusiness();
  const isEdit = useMemo(() => !!editClient, [editClient]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      if (errors.includes(name)) {
        setErrors(errors.filter((field) => field !== name));
      }
      setNewClient((prev) => ({ ...prev, [name]: value }));
    },
    [errors],
  );

  const handleClearClient = useCallback(() => {
    setNewClient({});
  }, []);

  const validateNewClient = useCallback(() => {
    const newErrors = requiredFields.filter(
      (field) => !newClient?.[field as keyof IClient],
    );
    setErrors(newErrors);

    return newErrors.length === 0;
  }, [newClient]);

  const handleEditClient = useCallback(async () => {
    const updates = { ...newClient };
    const clientData = await dispatch(
      updateClient({ clientId: newClient?.id, updates }),
    );

    if (!clientData?.error) {
      toast({
        title: t('clients.client_updated'),
        variant: 'main',
        position: 'top-right',
      });
      onClose();
      setNewClient({});
      return;
    }

    if (clientData?.payload?.response?.data?.message) {
      toast({
        title: clientData?.payload?.response?.data?.message,
        variant: 'error',
        position: 'top-right',
      });
    }
  }, [dispatch, toast, t, onClose, newClient]);

  const handleCreateClient = useCallback(async () => {
    const newClientData = await dispatch(createClient({ client: newClient }));

    if (!newClientData?.error) {
      toast({
        title: t('clients.client_created'),
        variant: 'main',
        position: 'top-right',
      });
      if (isInviteToBrilliant) {
        dispatch(
          createInvite({
            email: newClientData.email,
            businessID: myBusiness?.id,
            type: 'client',
            fullName: `${newClientData.firstName || ''} ${
              newClientData.lastName || ''
            }`,
            phone: newClientData.phone,
          }),
        );
      }
      onClose();
      setNewClient({});
      return;
    }

    if (newClientData?.payload?.response?.data?.message) {
      toast({
        title: newClientData?.payload?.response?.data?.message,
        variant: 'error',
        position: 'top-right',
      });
    }
  }, [
    dispatch,
    toast,
    t,
    onClose,
    newClient,
    isInviteToBrilliant,
    myBusiness?.id,
  ]);

  const handleSubmit = useCallback(async () => {
    if (!validateNewClient()) return;

    if (isEdit) {
      await handleEditClient();
      return;
    }

    await handleCreateClient();
  }, [validateNewClient, isEdit, handleEditClient, handleCreateClient]);

  const handleFoundClient = useCallback((client: IClient) => {
    setNewClient(client);
  }, []);

  useEffect(() => {
    if (editClient) {
      setNewClient(editClient);
    } else {
      setNewClient({});
    }
  }, [editClient]);

  return {
    newClient,
    handleChange,
    handleSubmit,
    errors,
    isInviteToBrilliant,
    setIsInviteToBrilliant,
    handleFoundClient,
    handleClearClient,
    isEdit,
  };
}
