import { Button, Divider, Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PreviewQuote from '../previewQuote';
import CreateQuoteHeader from './components/CreateQuoteHeader';
import CustomerDetails from './components/CustomerDetails';
import PaymentDetails from './components/PaymentDetails';
import Products from './components/Products';
import useCreateQuote from './hooks/useCreateQuote';

export default function CreateQuote() {
  const { t } = useTranslation();
  const {
    newQuote,
    handleChange,
    handleAddProduct,
    handleChangeEventType,
    selectedEventType,
    handleOpenEventDialog,
    handleAddCustomer,
    handleAddPayment,
    handleCreateQuote,
    handleIncreasePaymentQuantity,
    handleDecreasePaymentQuantity,
    event,
    setNewQuote,
    isLoading,
    products,
    handleSelectedProduct,
    handleChangeFile,
    clearFile,
    handleChangePaymentAmount,
    currencyIcon,
    error,
    handleAutoCompleteContactDetails,
  } = useCreateQuote();

  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '57px' }}
      flexDirection="column"
      gap={4}>
      <Card gap="36px">
        <CreateQuoteHeader
          handleChange={handleChange}
          newQuote={newQuote}
          handleChangeEventType={handleChangeEventType}
          selectedEventType={selectedEventType}
          handleOpenEventDialog={handleOpenEventDialog}
          event={event}
        />
        <CustomerDetails
          handleAddCustomer={handleAddCustomer}
          setNewQuote={setNewQuote}
          newQuote={newQuote}
          error={error}
          handleAutoCompleteContactDetails={handleAutoCompleteContactDetails}
        />
        <Flex
          w="100%"
          flexDirection={{ base: 'column', md: 'row' }}
          gap="16px"
          h="100%">
          <Products
            handleAddProduct={handleAddProduct}
            newQuote={newQuote}
            handleChange={handleChange}
            setNewQuote={setNewQuote}
            products={products}
            handleSelectedProduct={handleSelectedProduct}
            handleChangeFile={handleChangeFile}
            clearFile={clearFile}
            currencyIcon={currencyIcon}
            error={error}
          />
          <Divider
            orientation="vertical"
            borderColor="#EBEDF0"
            h="900px"
            display={{ base: 'none', md: 'block' }}
          />
          <PaymentDetails
            newQuote={newQuote}
            handleAddPayment={handleAddPayment}
            handleIncreasePaymentQuantity={handleIncreasePaymentQuantity}
            handleDecreasePaymentQuantity={handleDecreasePaymentQuantity}
            setNewQuote={setNewQuote}
            currencyIcon={currencyIcon}
            error={error}
            handleChangePaymentAmount={handleChangePaymentAmount}
          />
        </Flex>
        <Flex justify="flex-end">
          <Button
            isLoading={isLoading}
            disabled={
              error?.customerName || error?.products || error?.totalPayment
            }
            variant="h1cta"
            w="240px"
            onClick={handleCreateQuote}>
            {t('create_quote.save')}
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
}
