import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import Card from 'components/card/Card';
import { RootState } from 'contexts/redux/store';
import { getTasks } from 'contexts/redux/task/taskSlice';
import { motion } from 'framer-motion';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IEvent, ITask, MeetingWithCalendar } from 'services/@types';
import TaskCard from '../../dashboard/components/TaskCard';
import TaskHistoryIcon from '../../../../assets/svg/event/clockRewind.svg';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import SuppierMeetingItem from './SuppierMeetingItem';

type TaskListProps = {
  meetingsAndEventDetails: {
    meetings: MeetingWithCalendar[];
    eventDetails: IEvent;
  };
  isLoading: boolean;
  additionalProps?: any;
  shouldScroll?: boolean;
};
export default function TaskList({
  additionalProps,
  isLoading,
  meetingsAndEventDetails,
  shouldScroll,
}: TaskListProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { style, ref } = useHighlightAnimation('tasks');
  const params = useParams();
  const { tasks } = useSelector((state: RootState) => state.task);
  const dispatch = useDispatch<any>();

  const { isLoading: isTasksLoading } = useQuery({
    queryKey: ['tasks', params?.id],
    queryFn: async () =>
      await dispatch(
        getTasks({
          event: params?.id,
          status: 'pending',
          sortBy: 'priority',
        }),
      ),
    enabled: !!params?.id,
  });

  const todayMeetings = useMemo(() => {
    if (!meetingsAndEventDetails?.meetings) return [];
    const result = meetingsAndEventDetails?.meetings.filter((meeting) => {
      return meeting.isAnchorMeeting;
    });
    return result;
  }, [meetingsAndEventDetails?.meetings]);

  const Tasks = memo(
    ({
      isTasksLoading,
      tasks,
      todayMeetings,
    }: {
      isTasksLoading: boolean;
      tasks: ITask[];
      todayMeetings: MeetingWithCalendar[];
    }) => {
      const memoizedTasksAndMeetings = useMemo(
        () =>
          [...todayMeetings, ...tasks].sort((a, b) => {
            const dateA =
              'startDate' in a
                ? new Date(a.startDate)
                : new Date((a as ITask).dateAndTime);
            const dateB =
              'startDate' in b
                ? new Date(b.startDate)
                : new Date((b as ITask).dateAndTime);

            return dateB.getTime() - dateA.getTime();
          }),
        [tasks, todayMeetings],
      );

      return (
        <>
          <HStack justifyContent="space-between" mb="8px"></HStack>
          {isTasksLoading && (
            <HStack justifyContent="center" height="auto" align="center">
              <Spinner />
            </HStack>
          )}
          <VStack gap="8px" pb="17px" spacing={0}>
            {tasks?.length === 0 && todayMeetings.length === 0 && (
              <VStack height="100%" w="100%">
                <Text variant="taskCardSubTitle">{t('tasks.no_tasks')}</Text>
                <Button
                  onClick={() =>
                    navigate(`/main/tasks/create?eventId=${params?.id}`)
                  }
                  variant="h1cta">
                  {t('tasks.create_new_task')}
                </Button>
              </VStack>
            )}
            {memoizedTasksAndMeetings.map((meetingOrTask) => {
              if ('isAnchorMeeting' in meetingOrTask) {
                const data = meetingOrTask as MeetingWithCalendar;

                return (
                  <SuppierMeetingItem
                    meeting={data}
                    key={data.id}
                    onTop={data.isAnchorMeeting ? true : false}
                  />
                );
              } else {
                const data = meetingOrTask as ITask;
                return (
                  <TaskCard
                    key={data.id}
                    task={data}
                    onTop={data.priority === 'important' ? true : false}
                  />
                );
              }
            })}
          </VStack>
        </>
      );
    },
    (prevProps, nextProps) => {
      return (
        prevProps.isTasksLoading === nextProps.isTasksLoading &&
        prevProps.tasks === nextProps.tasks &&
        prevProps.todayMeetings === nextProps.todayMeetings
      );
    },
  );
  return (
    <Card
      {...style}
      ref={ref}
      flexDirection="column"
      w="100%"
      // minW={310}
      maxHeight="535px"
      flex={1}
      borderRadius={{ base: 0, md: '30px' }}
      p="24px 16px 0"
      height="100%"
      // maxH="580px"
      overflow="hidden"
      gap={3}
      spacing={0}
      {...additionalProps}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="cardTitle">{t('event.event_tasks')}</Text>
        <Flex align="center" gap="15px">
          <Flex
            w="24px"
            h="24px"
            bg="#8E7A7033"
            align="center"
            justify="center"
            borderRadius="6px"
            transition="all 0.2s ease-in-out"
            _hover={{ opacity: 0.6 }}
            onClick={() => navigate(`/main/event/${params?.id}/tasks-history`)}>
            <Image
              src={TaskHistoryIcon}
              alt="history"
              w="17px"
              h="17px"
              minW="17px"
              cursor="pointer"
            />
          </Flex>
          <IconButton
            bg="brand.400"
            aria-label="plus"
            w="24px"
            h="24px"
            minW="24px"
            cursor="pointer"
            onClick={() => navigate(`/main/tasks/create?eventId=${params?.id}`)}
            icon={<Icon as={FaPlus} color="warmWhite.100" />}
          />
        </Flex>
      </Flex>

      <Flex
        as={motion.div}
        initial={{ opacity: 0, scale: 0.98 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.98 }}
        flexDirection="column"
        overflow={shouldScroll ? 'auto' : 'hidden'}>
        <Tasks
          isTasksLoading={isTasksLoading}
          tasks={tasks}
          todayMeetings={todayMeetings}
        />
      </Flex>
    </Card>
  );
}
