import { Flex, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useHighlightAnimation } from 'hooks/useHighlightAnimation';
import { useTranslation } from 'react-i18next';

type MyContractProps = {
  additionalProps?: any;
};

const MyContract = ({ additionalProps }: MyContractProps) => {
  const { t } = useTranslation();
  const { style, ref } = useHighlightAnimation('my_contract');
  return (
    <Card
      ref={ref}
      {...style}
      maxHeight="620px"
      minHeight="451px"
      w="100%"
      flex="1"
      borderRadius={{ base: 0, md: '30px' }}
      gap="20px"
      spacing={0}
      {...additionalProps}>
      <Flex alignItems="center" justify="space-between">
        <Text variant="eventPageCardTitle">{t('event.my_contract')}</Text>
      </Flex>
    </Card>
  );
};

export default MyContract;
