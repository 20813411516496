import { Flex } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { useTranslation } from 'react-i18next';
import { IClient, IQoute } from 'services/@types';
import { useFindClient } from 'views/main/managment/clients/hooks/useFindClient';
const fields = [
  { name: 'firstName', label: 'create_quote.name' },
  { name: 'lastName', label: 'create_quote.last_name' },
  { name: 'phone', label: 'create_quote.phone' },
  { name: 'email', label: 'create_quote.email' },
  { name: 'idNumber', label: 'create_quote.id' },
];

const observedFields: (keyof IClient)[] = ['phone', 'email', 'idNumber'];

export default function CustomerDetailsForm({
  customer,
  setNewQuote,
  index,
  error,
  handleAutoCompleteContactDetails,
}: {
  customer: IQoute['customerDetails'][number];
  setNewQuote: React.Dispatch<React.SetStateAction<IQoute>>;
  index: number;
  error: {
    customerName: boolean;
  };
  handleAutoCompleteContactDetails: (client: IClient, key: number) => void;
}) {
  const { t } = useTranslation();
  const { findClient } = useFindClient({
    observedFields,
    observedObject: customer,
    onConfirm: (client) => handleAutoCompleteContactDetails(client, index),
  });
  return (
    <Flex wrap="wrap" gap="12px" width="100%">
      {fields.map((field) => (
        <InputField
          key={field.name}
          label={t(field.label)}
          placeholder={t(field.label)}
          value={customer[field.name as keyof typeof customer]}
          borderColor={
            error.customerName && field.name === 'firstName'
              ? 'error.100'
              : 'gray.200'
          }
          flex="1"
          minWidth={{ base: '100%', md: '260px' }}
          w={{ base: '100%', md: '260px' }}
          name={field.name}
          onBlur={(e) => {
            if (observedFields.includes(e.target.name as keyof IClient)) {
              findClient();
            }
          }}
          onChange={(e) =>
            setNewQuote((prev) => ({
              ...prev,
              customerDetails: prev.customerDetails.map((c, key) =>
                index === key ? { ...c, [field.name]: e.target.value } : c,
              ),
            }))
          }
        />
      ))}
    </Flex>
  );
}
