import {
  Button,
  Checkbox,
  Flex,
  Hide,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { CloseIcon } from '@chakra-ui/icons';
import useClientForm from '../hooks/useClientForm';
import { IClient } from 'services/@types';
import { useFindClient } from '../hooks/useFindClient';

interface AddClientModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const fields = [
  { key: 'firstName', type: 'text', isRequired: true },
  { key: 'lastName', type: 'text', isRequired: true },
  { key: 'company', type: 'text' },
  { key: 'phone', type: 'text', isRequired: true },
  { key: 'email', type: 'email', isRequired: true },
  { key: 'website', type: 'text' },
  { key: 'address', type: 'text' },
  { key: 'idNumber', type: 'text' },
  { key: 'socialProfile', type: 'text' },
];

type inputKeys = Omit<IClient, 'media' | 'id' | 'createdAt' | 'updatedAt'>;

const observedFields: (keyof IClient)[] = ['phone', 'email'];

export default function AddClientModal(props: AddClientModalProps) {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();
  const {
    newClient,
    handleChange,
    handleSubmit,
    errors,
    isInviteToBrilliant,
    setIsInviteToBrilliant,
    handleFoundClient,
    handleClearClient,
    isEdit,
  } = useClientForm({
    onClose,
  });

  const { findClient } = useFindClient({
    observedObject: newClient,
    onConfirm: handleFoundClient,
    observedFields,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        handleClearClient();
      }}
      isCentered
      size="6xl">
      <ModalOverlay />
      <ModalContent
        p="24px"
        gap="50px"
        borderRadius={{ base: '0px', md: '30px' }}
        flexDir="column"
        h={{ base: '100%', md: 'auto' }}
        overflowY="auto">
        <Flex justifyContent="space-between">
          <Flex flexDir="column" gap={1}>
            <Text variant="previewQuoteContactTitle">
              {isEdit ? t('clients.edit_client') : t('clients.add_client')}
            </Text>
            <Text
              variant="addClientSubTitle"
              display={isEdit ? 'none' : 'block'}>
              {t('clients.add_client_description')}
            </Text>
          </Flex>
          <Hide above="md">
            <IconButton
              aria-label="close"
              icon={<Icon as={CloseIcon} color="#939393" w="12px" h="12px" />}
              onClick={() => {
                onClose();
                handleClearClient();
              }}
            />
          </Hide>
        </Flex>
        <Flex flexDir="column" gap="24px">
          <Flex flexWrap="wrap" gap="47px">
            {fields.map((field) => (
              <InputField
                key={field.key}
                name={field.key}
                value={newClient?.[field.key as keyof inputKeys] || ''}
                onChange={handleChange}
                flex={{ base: '1', md: '1 1 30%' }}
                minW={{ base: '100%', md: '320px' }}
                w={{ base: '100%', md: '320px' }}
                label={
                  t(`clients.${field.key}`) + (field.isRequired ? ' *' : '')
                }
                borderColor={
                  errors.includes(field.key) ? 'red.500' : 'gray.300'
                }
                onBlur={(e) => {
                  if (
                    observedFields?.includes(e.target.name as keyof IClient)
                  ) {
                    findClient();
                  }
                }}
                placeholder={t(`clients.${field.key}`)}
                type={field.type}
              />
            ))}
          </Flex>
          <TextField
            label={t('clients.note')}
            placeholder={t('clients.note')}
            tooltipTopLabel={t('clients.note_tooltip')}
            value={newClient?.note || ''}
            name="note"
            onChange={handleChange}
          />
          {!isEdit && (
            <Checkbox
              variant="roundBrand"
              borderColor="#AA915D"
              isChecked={isInviteToBrilliant}
              onChange={(e) => setIsInviteToBrilliant(e.target.checked)}
              alignItems="center">
              <Text variant="inviteToBrilliant">
                {t('clients.invite_to_brilliant')}
              </Text>
            </Checkbox>
          )}
        </Flex>
        <Flex justifyContent="center" w="100%">
          <Button
            variant="h1cta"
            isDisabled={errors.length > 0}
            w={{ base: '100%', md: '320px' }}
            onClick={handleSubmit}>
            {t('clients.save')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
