import {
  Button,
  HStack,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { createInvite, updateInvite } from 'contexts/redux/invite/inviteSlice';
import { AppDispatch, RootState } from 'contexts/redux/store';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdInfoOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Invite } from 'services/@types';
import { CheckboxItem } from './CheckboxItem';

const AccessLevels = ['admin', 'manager'];
export const AddSeatModal: React.FC = () => {
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [seatProps, setSeatProps] = useState<Partial<Invite>>({});
  const { loading, invites } = useSelector((state: RootState) => state.invites);
  const cancelledSeatInvites = useMemo(
    () =>
      invites.filter(
        (invite: Invite) =>
          invite.type === 'seat' && invite.status === 'cancelled',
      ),
    [invites],
  );
  const dispatch = useDispatch<AppDispatch>();
  const business = useMyBusiness();

  const dialog = useSelector((state: RootState) => state.dialogs.addSeatDialog);
  const { selectedSeat, editAllowed } = useMemo(() => {
    if (dialog?.item?.id) {
      const { editAllowed, ...selectedSeat } = dialog?.item;
      return {
        selectedSeat: selectedSeat as Invite,
        editAllowed: editAllowed as boolean,
      };
    } else {
      return {
        selectedSeat: undefined,
        editAllowed: false,
      };
    }
  }, [dialog]);

  const sendInviteButtonRef = React.useRef<HTMLButtonElement>(null);
  const handleValueChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSeatProps((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    },
    [],
  );
  useEffect(() => {
    if (dialog?.item) {
      onOpen();
      setSeatProps(dialog.item);
      setTimeout(() => {
        sendInviteButtonRef.current?.focus();
      }, 100);
    } else {
      onClose();
    }
  }, [dialog, onOpen, onClose, setSeatProps]);

  const handleUpdateInvite = useCallback(async () => {
    if (selectedSeat) {
      const updates: Partial<Invite> = {};

      if (seatProps.email !== selectedSeat.email) {
        updates.email = seatProps.email;
      }
      if (seatProps.fullName !== selectedSeat.fullName) {
        updates.fullName = seatProps.fullName;
      }
      if (seatProps.jobTitle !== selectedSeat.jobTitle) {
        updates.jobTitle = seatProps.jobTitle;
      }
      if (seatProps.role !== selectedSeat.role) {
        updates.role = seatProps.role;
      }
      await dispatch(
        updateInvite({
          userId: selectedSeat.id,
          updates,
        }),
      );
    }
  }, [dispatch, seatProps, selectedSeat]);
  const handleResendInvite = useCallback(
    async (invite: Invite) => {
      const response = await dispatch(
        updateInvite({
          userId: invite.id,
          updates: { status: 'pending' },
        }),
      );
      const payload = response.payload as any;
      console.log('#Redux cancelInvite response ->', payload);
      // const response = (payload as any).response;
      payload as any;
      if (payload.status === 409) {
        toast({
          title: t(payload.title),
          description: t(payload.content),
          status: 'error',
        });
      } else if (payload.status >= 400) {
        toast({
          title: t('error_messages.400'),
          // description: t('error_messages.400'),
          status: 'error',
        });
      } else {
        toast({
          title: t('invite_seat.invite_resent'),
          status: 'success',
          variant: 'main',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        });
      }
    },
    [dispatch, t, toast],
  );

  const handleSendInvite = useCallback(async () => {
    // check if email is already invited but cancelled
    const foundCancelledInvite = cancelledSeatInvites.find(
      (i) => i.email === seatProps.email,
    );
    if (foundCancelledInvite) {
      await handleResendInvite(foundCancelledInvite);
    } else {
      const response = await dispatch(
        createInvite({
          ...seatProps,
          businessID: business?.id,
          type: 'seat',
        } as Invite),
      );
      const payload = response.payload as any;
      console.log('#Redux createInvite response ->', payload);
      // const response = (payload as any).response;
      payload as any;
      if (payload.status === 409) {
        toast({
          title: t(payload.title),
          description: t(payload.content),
          status: 'error',
        });
      } else if (payload.status >= 400) {
        toast({
          title: t('error_messages.400'),
          // description: t('error_messages.400'),
          status: 'error',
        });
      } else {
        toast({
          title: t('invite_seat.invite_sent'),
          status: 'success',
          variant: 'main',
          duration: 3000,
          isClosable: true,
          position: 'bottom',
        });
      }
    }
  }, [
    cancelledSeatInvites,
    seatProps,
    handleResendInvite,
    dispatch,
    business?.id,
    toast,
    t,
  ]);

  const isFormValid = useCallback(() => {
    return (
      seatProps?.email?.trim() && seatProps?.fullName?.trim() && seatProps?.role
    );
  }, [seatProps?.email, seatProps?.fullName, seatProps?.role]);

  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isMobile ? 'full' : 'xl'}>
      <ModalOverlay />
      <ModalContent
        maxW="740px"
        borderRadius={{ base: 'none', md: '3xl' }}
        p={6}>
        <ModalHeader p={0} mb={5}>
          <VStack w="100%" align="start" spacing={0}>
            <Text fontSize="2xl">
              {t(
                `the_office.${
                  editAllowed ? 'edit_seat_modal' : 'add_seat_modal'
                }.title`,
              )}
            </Text>
            <Text
              fontSize={{ base: 'md', sm: 'sm', md: 'md' }}
              fontWeight={'300'}>
              {t(
                `the_office.${
                  editAllowed ? 'edit_seat_modal' : 'add_seat_modal'
                }.subtitle`,
              )}
            </Text>
          </VStack>
          <ModalCloseButton
            position="absolute"
            right={i18n.dir() !== 'rtl' ? 2 : 'auto'}
            left={i18n.dir() !== 'ltr' ? 2 : 'auto'}
          />
        </ModalHeader>
        <HStack
          align="start"
          gap={10}
          justify={{ base: 'center', md: 'space-between' }}
          wrap={{ base: 'wrap', md: 'nowrap' }}>
          <VStack
            gap={3}
            flex={1}
            maxW={{ base: '100%', md: '310px' }}
            minW={310}>
            <InputField
              name="email"
              w="full"
              value={seatProps?.email}
              label={t('the_office.add_seat_modal.email')}
              onChange={handleValueChanged}
              isRequired
              disabled={selectedSeat?.email}
            />
            <InputField
              value={seatProps?.fullName}
              w="full"
              name="fullName"
              label={t('the_office.add_seat_modal.full_name')}
              onChange={handleValueChanged}
              isRequired
              disabled={selectedSeat?.email}
            />
            <InputField
              w="full"
              value={seatProps?.jobTitle}
              name="jobTitle"
              label={t('the_office.add_seat_modal.job_title')}
              onChange={handleValueChanged}
            />
          </VStack>
          <VStack align="start" gap={10} w="100%">
            <VStack align="start" gap={4} w="100%" spacing={0}>
              <HStack>
                <Text fontSize={'xl'} fontWeight="bold">
                  {t('the_office.add_seat_modal.account_restrictions')}
                </Text>
                <Tooltip
                  hasArrow
                  variant="business"
                  arrowShadowColor="#00000010"
                  label={t(
                    'the_office.add_seat_modal.account_restrictions_tooltip',
                  )}
                  aria-label="Add or remove members to your account. manage account permissions">
                  <IconButton
                    aria-label="info"
                    bg="transparent"
                    fontSize={'2xl'}
                    icon={<MdInfoOutline />}
                    color="brand.600"
                  />
                </Tooltip>
              </HStack>
              <Stack gap={4} direction={'column'} w="100%">
                {AccessLevels.map((role) => (
                  <CheckboxItem
                    label={t(`the_office.add_seat_modal.roles.${role}.label`)}
                    description={t(
                      `the_office.add_seat_modal.roles.${role}.description`,
                    )}
                    value={role}
                    onChange={() =>
                      handleValueChanged({
                        target: { name: 'role', value: role },
                      } as any)
                    }
                    checked={role === seatProps.role}
                  />
                ))}
              </Stack>
            </VStack>
            <Button
              w="full"
              isLoading={loading}
              onClick={() => {
                if (selectedSeat) {
                  handleUpdateInvite();
                } else {
                  handleSendInvite();
                }
              }}
              variant="h1cta"
              px={10}
              isDisabled={
                selectedSeat ? !editAllowed && !isFormValid() : !isFormValid()
              }
              ref={sendInviteButtonRef}>
              {t(
                `the_office.${
                  selectedSeat
                    ? 'edit_seat_modal.save_changes'
                    : 'add_seat_modal.send_invite'
                }`,
              )}
            </Button>
          </VStack>
        </HStack>

        {/* <Button
                    alignSelf="center"
                    variant="outlineRed"
                // onClick={handleSaveClicked}
                >
                    {t('the_office.add_seat_modal.delete_team_member')}
                </Button> */}
      </ModalContent>
    </Modal>
  );
};
